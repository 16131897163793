import { BASE_URL } from 'src/config';
import axiosInstance from 'src/utils/axios';
import { getQueryParams } from 'src/utils/queryParams';

export const fetchClubTrainers = (page = 1, clubId) => {
  const url = clubId
    ? `${BASE_URL}/api/club/${clubId}/trainers`
    : `${BASE_URL}/api/club/trainers`;
  return axiosInstance.get(`${url}?page=${page}`);
};

export const getClubTrainer = trainerId => {
  return axiosInstance.get(`${BASE_URL}/api/club/trainers/${trainerId}`);
};

export const createClubTrainer = trainer => {
  return axiosInstance.post(`${BASE_URL}/api/club/trainers`, trainer);
};

export const updateClubTrainer = ({
  club_id,
  sport_id,
  branch_id,
  trainer_id,
  subscription,
  subscription_period,
  subscription_period_unit
}) => {
  return axiosInstance.post(`${BASE_URL}/api/club/trainers`, {
    club_id,
    trainer_id,
    subscription,
    subscription_period,
    subscription_period_unit,
    sport_id,
    branch_id
  });
};

export const deleteClubTrainer = trainerId => {
  const { page } = getQueryParams();
  return axiosInstance.delete(
    `${BASE_URL}/api/clubs/trainers/${trainerId}?page=${parseInt(
      page || 1,
      10
    ) + 1}`
  );
};
