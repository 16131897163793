import { BASE_URL } from 'src/config';
import axiosInstance from 'src/utils/axios';
import { getQueryParams } from 'src/utils/queryParams';

export const fetchCertificates = (page = 1, trainerId) => {
  return axiosInstance.get(
    `${BASE_URL}/api/trainer/certificates?page=${page}&trainer_id=${trainerId}`
  );
};

export const getCertificate = certificateId => {
  return axiosInstance.get(
    `${BASE_URL}/api/trainer/certificates/${certificateId}`
  );
};

export const createCertificate = certificate => {
  return axiosInstance.post(
    `${BASE_URL}/api/trainer/certificates`,
    certificate
  );
};

export const updateCertificate = (id, certificate) => {
  return axiosInstance.post(
    `${BASE_URL}/api/trainer/certificates/${id}`,
    certificate
  );
};

export const deleteCertificate = certificateId => {
  const { page } = getQueryParams();
  return axiosInstance.delete(
    `${BASE_URL}/api/trainer/certificates/${certificateId}?page=${parseInt(
      page || 0,
      10
    ) + 1}`
  );
};
