import { BASE_URL } from 'src/config';
import axiosInstance from 'src/utils/axios';

export const fetchContactUs = (page = 1, query) => {
  return axiosInstance.get(
    `${BASE_URL}/api/contact-us?page=${page}&title=${query || ''}`
  );
};

export const getContact = contactId => {
  return axiosInstance.get(`${BASE_URL}/api/contact-us/${contactId}`);
};

export const updateContactStatus = (contactId, status) => {
  return axiosInstance.patch(`${BASE_URL}/api/contact-us/${contactId}`, {
    status
  });
};
