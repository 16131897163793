import { BASE_URL } from 'src/config';
import axiosInstance from 'src/utils/axios';
import { getQueryParams } from 'src/utils/queryParams';

export const fetchClubBranches = (page = 1, clubId) => {
  const url = clubId
    ? `${BASE_URL}/api/club/${clubId}/branches`
    : `${BASE_URL}/api/club/branches`;
  return axiosInstance.get(`${url}?page=${page}`);
};

export const getClubBranch = branchId => {
  return axiosInstance.get(`${BASE_URL}/api/club/branches/${branchId}`);
};

export const createClubBranch = branch => {
  return axiosInstance.post(`${BASE_URL}/api/club/branches`, branch);
};

export const updateClubBranch = ({
  club_id,
  area_id,
  address_details,
  latitude,
  longitude,
  title,
  title_ar,
  branchId
}) => {
  return axiosInstance.patch(`${BASE_URL}/api/club/branches/${branchId}`, {
    club_id,
    area_id,
    address_details,
    latitude,
    longitude,
    title,
    title_ar
  });
};

export const deleteClubBranch = branchId => {
  const { page } = getQueryParams();
  return axiosInstance.delete(
    `${BASE_URL}/api/club/branches/${branchId}?page=${parseInt(page || 1, 10) +
      1}`
  );
};
