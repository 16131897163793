import { BASE_URL } from 'src/config';
import axiosInstance from 'src/utils/axios';

export const loginUser = (user) => {
  return axiosInstance.post(`${BASE_URL}/api/user/login`, user);
};

export const getUser = () => {
  return axiosInstance.get(`${BASE_URL}/api/user/profile`);
};
