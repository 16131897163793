import { BASE_URL } from 'src/config';
import axiosInstance from 'src/utils/axios';
import { getQueryParams } from 'src/utils/queryParams';

export const fetchTrainers = (page = 1, query) => {
  return axiosInstance.get(
    `${BASE_URL}/api/trainers?page=${page}&nickname=${query || ''}`
  );
};

export const fetchTrainersOptions = () => {
  return axiosInstance.get(`${BASE_URL}/api/trainers?noPaginate=true`);
};

export const getTrainer = trainerId => {
  return axiosInstance.get(`${BASE_URL}/api/trainers/${trainerId}`);
};

export const createTrainer = trainer => {
  return axiosInstance.post(`${BASE_URL}/api/trainers`, trainer);
};

export const updateTrainer = (id, trainer) => {
  return axiosInstance.post(`${BASE_URL}/api/trainers/${id}`, trainer);
};

export const deleteTrainer = trainerId => {
  const { page } = getQueryParams();
  return axiosInstance.delete(
    `${BASE_URL}/api/trainers/force-delete/${trainerId}?page=${parseInt(
      page || 1,
      10
    ) + 1}`
  );
};
