import { BASE_URL } from 'src/config';
import axiosInstance from 'src/utils/axios';
import { getQueryParams } from 'src/utils/queryParams';

export const fetchClubs = (page = 1) => {
  return axiosInstance.get(`${BASE_URL}/api/clubs?page=${page}`);
};

export const getClub = clubId => {
  return axiosInstance.get(`${BASE_URL}/api/clubs/${clubId}`);
};

export const createClub = club => {
  return axiosInstance.post(`${BASE_URL}/api/clubs`, club);
};

export const updateClub = (id, club) => {
  return axiosInstance.post(`${BASE_URL}/api/clubs/${id}`, club);
};

export const deleteClub = clubId => {
  const { page } = getQueryParams();
  return axiosInstance.delete(
    `${BASE_URL}/api/clubs/force-delete/${clubId}?page=${parseInt(
      page || 1,
      10
    ) + 1}`
  );
};
