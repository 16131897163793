// dialog
export const OPEN_DIALOG = 'OPEN_DIALOG';
export const CLOSE_DIALOG = 'CLOSE_DIALOG';
export const DIALOG_LOADING = 'DIALOG_LOADING';

// pagination
export const CHANGE_PAGE = 'CHANGE_PAGE';

// role
export const FETCH_ROLES = 'FETCH_ROLES';
export const DELETE_ROLE = 'DELETE_ROLE';

// sport
export const FETCH_SPORTS = 'FETCH_SPORTS';

// club
export const FETCH_CLUBS = 'FETCH_CLUBS';

// trainer
export const FETCH_TRAINERS = 'FETCH_TRAINERS';
export const FETCH_CLUB_TRAINERS = 'FETCH_CLUB_TRAINERS';
export const FETCH_CLUB_BRANCHES = 'FETCH_CLUB_BRANCHES';
export const FETCH_CLUB_SPORTS = 'FETCH_CLUB_SPORTS';
export const FETCH_TRAINER_SPORTS = 'FETCH_TRAINER_SPORTS';

// area
export const FETCH_AREAS = 'FETCH_AREAS';

// governorate
export const FETCH_GOVERNORATES = 'FETCH_GOVERNORATES';

// city
export const FETCH_CITIES = 'FETCH_CITIES';

// certificate
export const FETCH_CERTIFICATES = 'FETCH_CERTIFICATES';

// slider
export const FETCH_SLIDERS = 'FETCH_SLIDERS';

// sponsor
export const FETCH_SPONSORS = 'FETCH_SPONSORS';

// faqs
export const FETCH_FAQS = 'FETCH_FAQS';

// faqs
export const FETCH_SPECIAL_ADS = 'FETCH_SPECIAL_ADS';

// offer
export const FETCH_OFFERS = 'FETCH_OFFERS';

// page
export const FETCH_PAGES = 'FETCH_PAGES';
