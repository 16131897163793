import { BASE_URL } from 'src/config';
import axiosInstance from 'src/utils/axios';
import { getQueryParams } from 'src/utils/queryParams';

export const fetchSports = (page = 1, title) => {
  return axiosInstance.get(
    `${BASE_URL}/api/sports?page=${page}&title=${title || ''}`
  );
};

export const fetchSportsOptions = () => {
  return axiosInstance.get(`${BASE_URL}/api/sports?noPaginate=true`);
};

export const getSport = sportId => {
  return axiosInstance.get(`${BASE_URL}/api/sports/${sportId}`);
};

export const createSport = sport => {
  return axiosInstance.post(`${BASE_URL}/api/sports`, sport);
};

export const updateSport = sport => {
  return axiosInstance.patch(`${BASE_URL}/api/sports/${sport.id}`, sport);
};

export const deleteSport = sportId => {
  const { page } = getQueryParams();
  return axiosInstance.delete(
    `${BASE_URL}/api/sports/${sportId}?page=${parseInt(page || 1, 10) + 1}`
  );
};
